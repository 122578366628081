<template>
  <div>
    <audio id="alarmA" :src="require('@/assets/alarm.mp3')" loop="true" />
    <div class="sosCall">
      <template v-for="(item, index) in sosTypeList">
        <div class="sosCallBox" v-if="item.showBoxType != 1" :key="index">
          <div v-if="item.cmd == 'push_sos'" class="newsosBox">
            <div style="position: relative;">
              <div class="colorBox" @click="closeSOS(item)">
                <i class="el-icon-close" style="float: right;"></i>
              </div>
              <el-image fit="contain"
                :src="item.showBoxType == 3 ? require(`@/assets/sosTitBg1.png`) : require(`@/assets/sosTitBg2.png`)"
                style="width:100%" />
              <div class="sosMsgHeader" style="color: #fff">
                <div class="sosTitle">
                  <p style="font-size:22px;font-weight: bolder">{{ item.sosName }}</p>
                  <p style="font-size:16px;">{{ item.closetime }}S</p>
                </div>
              </div>
            </div>
            <div class="sosMsgContent" style="padding: 0 20px;">
              <div class="sosMsg" style="display:flex;align-items: flex-start;">
                <p style="width:27%;">电梯名称：</p>
                <p style="width:72%" :title="item.name" class="overName">{{ item.name }} </p>
              </div>
              <div class="sosMsg">
                <p style="width:27%">报警时间：</p>
                <p style="width:72%">{{ item.time }} </p>
              </div>
              <div class="sosMsg">
                <p style="width:27%">报警楼层：</p>
                <!-- {{item}} -->
                <p style="width:72%">{{ item.floor }} </p>
              </div>
            </div>
            <div class="sosMsgBottom">
              <el-button type="primary" size="small" v-if="
              (item.type==0||
              item.type==5||
              item.type==13||
              item.type==95||
              item.type==10||
              item.type==4||
              item.type==7||
              item.type==1 ||
              item.type==2 ||
              item.type==3||
              item.type==11||
              item.type==12 ||
              item.type==14 ||
              item.type==999 ||
              item.type==98 ||
              item.type==96 ||
              item.type==19 ||
              item.type==20 ||
              item.type==21)&&item.code != ''
              " @click="answerSOS(item)" style="width:100px">接听</el-button>
              <el-button type="info" size="small" @click="closeSOS(item)"
                :class="item.code == ''? 'offdialog': 'offdialogs'">关闭弹窗</el-button>
            </div>
          </div>
          <div v-else-if="item.cmd == 'carport_push_sos'" class="newsosCapsBox">
            <!-- 车棚 -->
            <div style="position: relative;">
              <div class="colorBox" @click="closeSOS(item)">
                <i class="el-icon-close"></i>
              </div>
              <el-image fit="contain" :src="require(`@/assets/sosTitBg1.png`)" style="width:100%" />
              <div class="sosMsgHeader" style="color: #fff">
                <div class="sosTitle">
                  <p style="font-size:22px;font-weight: bolder">{{ item.sosName }}</p>
                  <p style="font-size:16px;">{{ item.closetime }}S</p>
                </div>
              </div>
            </div>
            <div class="sosMsgContent" style="padding: 10px 20px;">
              <div class="sosMsg" style="display:flex;align-items: flex-start;">
                <p style="width:27%;">电动车棚：</p>
                <p style="width:72%" :title="item.name" class="overName">{{ item.name }} </p>
              </div>
              <div class="sosMsg" style="margin: 10px 0">
                <p style="width:27%">报警时间：</p>
                <p style="width:72%">{{ item.time }} </p>
              </div>
              <div class="sosMsg">
                <p style="max-width:30%">报警摄像头：</p>
                <!-- {{item}} -->
                <p style="width:69%">{{ item.deviceName }} </p>
              </div>
            </div>
            <div class="sosMsgBottom">
              <el-button type="primary" size="small" v-if="item.code != ''
              " @click="answerCarSOS(item)" style="width:100px">接听</el-button>
              <el-button type="info" size="small" @click="closeSOS(item)"
                :class="item.code == ''? 'offdialog': 'offdialogs'">关闭弹窗</el-button>
            </div>
          </div>
          <div v-else class="newsosCapsBox">
            <!-- 安全帽 -->
            <div style="position: relative;">
              <div class="colorBox" @click="closeSOS(item)">
                <i class="el-icon-close"></i>
              </div>
              <el-image fit="contain" :src="require(`@/assets/sosTitBg2.png`)" style="width:100%" />
              <div class="sosMsgHeader" style="color: #fff">
                <div class="sosTitle">
                  <p style="font-size:22px;font-weight: bolder">{{ item.sosName }}</p>
                  <p style="font-size:16px;">{{ item.closetime }}S</p>
                </div>
              </div>
            </div>
            <div class="sosMsgContent" style="padding: 0 20px;">
              <div class="sosMsg" style="display:flex">
                <p style="width:27%">报警设备：</p>
                <p style="width:72%">安全帽 </p>
              </div>
              <div class="sosMsg">
                <p style="width:27%">人员名称：</p>
                <p style="width:72%">{{ item.userName }} </p>
              </div>
              <div class="sosMsg">
                <p style="width:27%">报警时间：</p>
                <p style="width:72%">{{ item.sosTime }} </p>
              </div>
              <div class="sosMsg" style="display:flex">
                <p style="width:27%">报警位置：</p>
                <p style="width:72%">{{ item.address }} </p>
              </div>
            </div>
            <div class="sosMsgBottom">
              <el-button type="primary" size="small" @click="answerSOSCaps(item)">接听</el-button>
              <el-button type="info" size="small" @click="closeSOS(item)" style="margin-left: 70px">关闭弹窗</el-button>
            </div>
          </div>
        </div>
      </template>
      <el-dialog v-if="visible" :visible.sync="visible" :close-on-click-modal="false" :show-close="showClose"
        width="1050px" top="8vh" :modal="false" @closed="bclose">
        <div style="padding: 0 20px" class="scrollElement">
          <EditionC2 :jkData="jkData" :sosId="sosId" :id="device_id" :dioDetailsItem="dioDetailsItem"
            :percentage="percentage" @changePercentage="changePercentage" @bclose="bclose" />
        </div>
        <div slot="footer" class="dialog-footer"></div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
  import EditionC2 from "../../../components/editionC2";
  import {
    formatDate
  } from "@/util";
  let sosTime;
  export default {
    data() {
      return {
        // 报警信息
        sosTypeList: [],
        jkData: null, // 球机推拉流
        visible: false,
        sosId: "",
        showClose: false,
        tpList: [],
        tpCarList: [],
        device_id: '',
        dioDetailsItem: {},
        percentage: 25,
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
      };
    },
    components: {
      EditionC2,
    },
    mounted() {

      this.init();
    },
    beforeDestroy() {
      clearInterval(sosTime);
    },
    methods: {
      bclose() {
        this.visible = false;
      },
      // 拒接
      closeSOS(data) {
        this.sosTypeList = this.sosTypeList.filter(
          (item) => item.sosId !== data.sosId
        );
        if (this.sosTypeList.length === 0) {
          document.getElementById("alarmA").pause();
        }
      },

      changePercentage(data) {

        this.percentage = data
      },
      getStreamStatus(data) {
        console.log(data, 'jkData');
        this.device_id = data.device_id
        this.$http
          .get(`api/ele/web/getStreamStatus?id=${this.device_id}`)
          .then((res) => {
            if (res.data.success) {
              this.percentage = res.data.data ? 75 : 50
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },

      // 接听
      answerSOS(data) {
        this.dioDetailsItem = data
        this.sosTypeList = this.sosTypeList.filter(
          (item) => item.sosId !== data.sosId
        );
        document.getElementById("alarmA").pause();
        this.sosId = data.sosId;
        this.visible = true;
        setTimeout(() => {
          this.showClose = true;
        }, 2000);
        const agentData = {
          act: "ca_start_rtsp",
          device_id: data.code,
        };
        this.socketApi.sendSock(agentData, (e) => {
          if (e.status) {
            if (e.hb) {
              this.percentage = 25
            }
            // if (e.cmd === 'ca_start_rtsp') {
            // 		if (data.appVer.indexOf('C2') > -1 || data.appVer.indexOf('C3') > -1) {
            // 			this.percentage = 50
            // 			this.getStreamStatus(JSON.parse(e.data))
            // 			this.isOldJk = true
            // 		} else {
            // 			this.isOldJk = false
            // 		}
            // 		const data1 = JSON.parse(e.data)
            // 		this.jkData = data1
            // 	}
            if (e.cmd === "ca_start_rtsp") {
              const data = JSON.parse(e.data);
              console.log("监控data", data);

              this.percentage = 50
              this.getStreamStatus(JSON.parse(e.data))
              this.jkData = data;
            }
          } else {
            this.$message.error(e.msg);
          }
        });
        console.log(agentData, "agentData");
      },

      //车棚接听
      answerCarSOS(data) {
        this.carVisible = true
        console.log(data, 'data111111111111');

        this.dioDetailsItem = data
        this.sosTypeList = this.sosTypeList.filter(
          (item) => item.sosId !== data.sosId
        )
        document.getElementById('alarmA').pause()
        this.sosId = data.sosId
        this.videoStatusId = data.videoStatusId
        this.alarmTime = data.alarmTime
        setTimeout(() => {
          this.showClose = true
        }, 2000)
      },

      bCarClose() {
        this.carVisible = false
      },
      init() {
        this.$http
          .post("/api/system/web/dict/dictionary?codes=SOS_TYPE,CARPORT_SOS_TYPE")
          .then((res) => {
            if (res.data.success) {
              const Arr = res.data.data.SOS_TYPE.filter((item) => {
                if (this.adminInfo.userName != 'admin') {
                  return item.value != '21'&&item.value != '35'
                } else {
                  return item
                }
              })
              this.tpList = Arr;
              sosTime = setInterval(() => {
                this.sosTypeList.forEach((qs, ind) => {
                  qs.closetime -= 1;
                  if (qs.closetime <= 0) {
                    this.sosTypeList.splice(ind, 1);
                  }
                });
                this.socketApi.sosPrompt.forEach((qs) => {
                  qs.closetime = 30;
                  qs.time = formatDate(qs.time, "yyyy-MM-dd HH:mm:ss")
                  qs.typeShow = true;
                  if (qs.type === -1) {
                    qs.sosName = `${qs.terminalSosName}报警`;
                  } else {
                    qs.sosName = this.getType(qs.type);
                  }
                  let sosConfigType = []
                  sosConfigType = JSON.parse(sessionStorage.getItem('sosConfig'))
                  sosConfigType.map(item => {
                    if (item.sosType == qs.type) {
                      qs.showBoxType = item.sosLevel
                    }
                  })
                  this.sosTypeList.push(qs);
                });


                // 车棚
                this.socketCarportApi.sosCarportPrompt.forEach((qs) => {
                  console.log(qs, 'qs111111111111111111111111');
                  if (this.sosTypeList.length > 9) {
                    this.sosTypeList.splice(0, 1)
                  }
                  qs.closetime = 30
                  qs.typeShow = true
                  qs.sosName = this.getCarType(qs.type)

                  this.sosTypeList.push(qs)
                })



                this.sosTypeList = this.sosTypeList.filter(item => {
                  if (item.cmd == 'carport_push_sos') {
                    let data = this.getCarSosConfigObj(item)
                    return data && data.push == 1
                  } else {
                    let data = this.getSosConfigObj(item)
                    return data && data.push == 1
                  }
                })
                let voiceS = this.sosTypeList.filter(item => {
                  let data = this.getSosConfigObj(item)
                  let data1 = ''
                  if (item.cmd == 'carport_push_sos') {
                    data1 = this.getCarSosConfigObj(item)
                    // console.log(11)
                    return (data1 && data1.voice == 1)
                  } else {
                    // console.log(22)
                    return (data && data.voice == 1)
                  }
                })
                if (
                  this.sosTypeList.length > 0 &&
                  !this.visible &&
                  !this.$global.openJK &&
                  voiceS.length > 0
                ) {
                  document.getElementById("alarmA").loop = true;
                  document.getElementById("alarmA").play();
                } else {
                  document.getElementById("alarmA").pause();
                }

                this.socketApi.clearSos();
                this.socketCarportApi.clearCarportSos();
              }, 1000);
            }
          });
      },
      getType(type) {
        let obj = "未知报警";
        this.tpList.forEach((qs) => {
          if (qs.value == type) {
            obj = qs.label;
            return obj;
          }
        });
        return obj;
      },

      getCarType(type) {
        let obj = '未知报警'
        this.tpCarList.forEach((qs) => {
          if (qs.value == type) {
            obj = qs.label
            return obj
          }
        })
        return obj
      },



      getSosConfigObj(data) {
        const sosConfig = JSON.parse(sessionStorage.getItem("sosConfig"))
        let arr = sosConfig.filter(item => item.sosType == data.type)
        return arr[0]
      },
      // 车棚
      getCarSosConfigObj(data) {
        const bikeSosConfig = JSON.parse(sessionStorage.getItem("bikeSosConfig"))
        let arr = bikeSosConfig.filter(item => Number(item.sosType) == Number(data.type))
        return arr[0]
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sosCall {
    position: absolute;
    top: 100px;
    left: 540px;
    z-index: 999;
    width: calc(100vw - 1080px);
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .sosCallBox {
    width: 365px;
    min-height: 315px;
    margin-right: 15px;
    margin-bottom: 15px;
    background-color: #fff;
    border-radius: 5px;

    .newsosBox,
    .newsosCapsBox {
      .colorBox {
        position: absolute;
        top: 10px;
        z-index: 999;
        right: 10px;
        color: #fff;
      }

      .sosMsgHeader {
        display: flex;
        // position: relative;
        z-index: 999;
        justify-content: space-between;
        padding: 10px;
        color: rgb(255, 255, 255);
        position: absolute;
        top: 30px;
        right: 25px;

        .sosTitle {
          width: 200px;
          line-height: 24px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }

      .sosMsgContent {
        .sosMsg {
          margin: 10px 0;
          display: flex;
          align-items: center;

          p {
            margin: 0;
          }
        }
      }

      .sosMsgBottom {
        padding: 0 20px;
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .offdialog {
        width: 100px;
      }

      .offdialogs {
        width: 100px;
        margin-left: 70px
      }
    }

    .newsosCapsBox {
      .sosMsgContent {
        .sosMsg {
          margin: 5px 0
        }
      }

      .sosMsgBottom {
        margin-top: 15px;
      }
    }
  }

  .sosCallBox span {
    font-size: 18px;
    font-weight: bold;
  }

  .sosCall p {
    margin: 0;
  }

  .overName {
    max-height: 61px;
    // line-height: 60px;
    overflow: hidden; // 溢出的内容隐藏
    text-overflow: ellipsis; // 溢出的部分用省略号...显示
    -webkit-line-clamp: 3; // 行数
    display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示
    -webkit-box-orient: vertical; // 设置或检索伸缩盒对象的子元素的排列方式
  }

  .sosTitle {
    width: 200px;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .sosMsg {
    margin-top: 10px;
    display: flex;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .sosMsgContent .sosMsg {
    margin: 10px 0;
    display: flex;
    align-items: center;
  }

  .newsosBox,
  .newsosCapsBox {
    .colorBox {
      width: 100%;
      height: 75px;
      position: absolute;
      top: 0;
      // padding-left: 40px;
    }

    .sosMsgHeader {
      display: flex;
      position: relative;
      z-index: 999;
      justify-content: space-between;
      padding: 10px;

      .sosTitle {
        width: 200px;
        line-height: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .sosMsgBottom {
      padding: 0 20px;
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
</style>
